import React from 'react';
// import DayOfForum from '../../components/AgendaSpiker/DayOfForum';
import SessionOfForum from '../../components/AgendaSpiker/SessionOfForum';

const AgendaSpiker = () => {
  return (
    <>
        {/* <DayOfForum /> */}
        <SessionOfForum />
    </>
  );
};

export default AgendaSpiker;