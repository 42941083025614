// slices/firstForumSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialFirstForum = {
  forum: {},
  speakers: [],
  news: [],
  photos: [],
};

const firstForumSlice = createSlice({
  name: "firstForumSlice",
  initialState: initialFirstForum,
  reducers: {
    getForum(state, action) {
      state.forum = {...action.payload};
    },
    getNews(state, action) {
      state.news = [...action.payload];
    },
    getSpeakers(state, action) {
      state.speakers = [...action.payload];
    },
    getPhotos(state, action) {
      state.photos = [...action.payload];
    },
  },
});

export default firstForumSlice;
export const firstForumSliceAction = firstForumSlice.actions;
