import React, { useEffect, useState } from "react";
import "../../pages/Media/Media.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../api/fetchData";
import { homeSliceAction } from "../../store/homeSlice";
import playButton from "../../assets/Home/playButton.png";

const Media = () => {
  const newsPerRow = 6;

  const lang = useSelector((state) => state.langReducer.lang);
  const videos = useSelector((state) => state.homeReducer.videos);
  const dispatch = useDispatch();

  const [next, setNext] = useState(newsPerRow);

  const handleMoreVideos = () => {
    setNext(next + newsPerRow);
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchData(!lang ? "az/videos" : "en/videos").then((data) =>
      dispatch(homeSliceAction.getVideos(data))
    );
  }, [dispatch, lang]);
  
  return (
    <>
      <div className="media-section latest-section">
        <div
          className="media-left-div"
          data-aos="zoom-in"
          data-aos-duration="700"
        ></div>
        <div className="container">
          <div className="media-content">
            <div
              className="media-content-div"
              data-aos="zoom-in"
              data-aos-duration="700"
            ></div>
            <span className="media-red-circle"></span>
            <h3 className="video-title-main">{!lang ? "Forumdan videoçarxlar" : "Short videos from Forum"}</h3>
          </div>
          <div className="gallery-con">
            {Object.values(videos)
              .slice(0, next)
              ?.map((item) => (
                <div className="swiper-content col-lg-3" data-aos="zoom-in"
                data-aos-duration="700" key={item.Id}>
                  <a
                    href={item.Video}
                    data-fancybox="gallery"
                    data-caption={item.Title}
                  >
                    <img alt="thumbnail" src={item.CoverImage} />
                    <img
                      className="slider-play-button"
                      src={playButton}
                      alt="play-button"
                    />
                  </a>

                  {/* <div className="gallery-img">
                    <img src={item?.CoverImage} alt="" />
                    <p className="time-p">{item.Time}</p>
                  </div> */}

                  <p
                    className="gallery-info"
                    // dangerouslySetInnerHTML={{ __html: item.Content }}
                  >{item.Title}</p>
                </div>
              ))}
          </div>
          <hr />
          <div className="gallery-more-div">
            {next < Object.keys(videos).length && (
              <button onClick={handleMoreVideos} className="gallery-more">
                {!lang ? "Daha Çox" : "More"}
              </button>
            )}
          </div>
        </div>
        <div className="media-right-div"></div>
      </div>
    </>
  );
};

export default Media;
