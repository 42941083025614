import React, { useEffect, useState } from "react";
import "./Forum1.css";
import "../../components/Home/Static.css";
import ImageGallery from "react-image-gallery";
import Slider from "react-slick";
import { newsSliceAction } from "../../store/newsSlice";
import bottompic from "../../assets/Home/bottom.png";

import { firstForumSliceAction } from "../../store/firstForumSlice";
import hero from "../../assets/Home/hero.jpg";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../api/fetchData";
import DOMPurify from "dompurify";
import { useSpring, animated } from "react-spring";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Statics from "../../components/Home/Statics";

function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}
const Forum1 = () => {
  const lang = useSelector((state) => state.langReducer.lang);
  const forum = useSelector((state) => state.firstForumReducer.forum);
  const news = useSelector((state) => state.firstForumReducer.news);
  const photos = useSelector((state) => state.firstForumReducer.photos);
  const speakers = useSelector((state) => state.firstForumReducer.speakers);
  const [scrollNav, setScrollNav] = useState(false);
  let sliderImages = [];
  //   const { newsId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(!lang ? `az/forum/1` : `en/forum/1`).then((data) => {
      dispatch(firstForumSliceAction.getForum(data.Forum));
      dispatch(firstForumSliceAction.getNews(data.Xəbərlər));
      dispatch(firstForumSliceAction.getPhotos(data.ForumImage));
      dispatch(firstForumSliceAction.getSpeakers(data.Speakers));
    });
  }, [lang, dispatch]);
  const changeNav = () => {
    if (window.innerWidth >= 768) {
      if (window.scrollY >= 1100) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    } else {
      if (window.scrollY >= 850) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, [scrollNav]);
  // console.log(photos);
  // sliderImages.push({
  //   original: photos[0].Image,
  //   thumbnail: photos[0].Image,
  // });

  for (let i = 0; i < photos.length; i++) {
    sliderImages.push({
      original: photos[i].Image,
      thumbnail: photos[i].Image,
    });
  }
  // console.log(sliderImages);
  const settingsNews = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settingsSpeakers = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 5,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //   ],
  // };

  const sanitizeAndTruncateText = (html, maxLength) => {
    // HTML içeriğini temizle
    const cleanHtml = DOMPurify.sanitize(html);

    // Temizlenmiş içeriği metne çevir
    const tempElement = document.createElement("div");
    tempElement.innerHTML = cleanHtml;
    const cleanText = tempElement.textContent || tempElement.innerText || "";

    // Metni kes ve "..." ekle
    if (cleanText.length <= maxLength) {
      return cleanText;
    }
    return cleanText.substring(0, maxLength) + "...";
  };

  return (
    <>
      <div className="background-container-forum1">
        <img className="background-image-forum1" src={forum.TitleImage} alt="Hero" />
        {/* <div className="text-overlay-forum1">
          <h3
            dangerouslySetInnerHTML={{
              __html: forum.Title,
            }}
          ></h3>
          <p style={{ color: "white" }}>{forum.Date_Converted}</p>
        </div> */}
      </div>
      <div className="container">
        <div className="about-forum1"
              data-aos="zoom-in"
              data-aos-duration="700">
          <h2>
            {!lang
              ? "Forum haqqında ümumi məlumat"
              : "General information about the Forum"}
          </h2>
          <p className="forum-about mt-4"
            dangerouslySetInnerHTML={{
              __html: forum.Information,
            }}
          ></p>
        </div>
      </div>

      <div className="static-container static-top">
        <h1 className="st-first">
          {!lang
            ? "I Şuşa Qlobal Media Forum Rəqəmlərdə"
            : "I Shusha Global Media Forum In Numbers"}
        </h1>
        <div className="container static-inner">
          
            <div
              className="static1"
              data-aos="zoom-in"
              data-aos-duration="700"
            >
              <h1>
                <Number n={120} />
              </h1>
              <p> {!lang
                        ? "Media subyektlərinin sayı"
                        : "Number of media entities"}</p>
            </div>
            <div
              className="static1"
              data-aos="zoom-in"
              data-aos-duration="700"
            >
              <h1>
              <Number n={250} />
              </h1>
              <p> {!lang
                        ? "İştirakçı sayı"
                        : "Number of participants"}</p>
            </div>
            <div
              className="static1"
              data-aos="zoom-in"
              data-aos-duration="700"
            >
              <h1>
              <Number n={50} />
              </h1>
              <p> {!lang
                        ? "Ölkələrin sayı"
                        : "Number of countries"}</p>
            </div>
         
        </div>
        <img id="form" className="bottom-img" src={bottompic} alt="" />
      </div>
      <div className="doc-up my-5">
        {/* <h2>{!lang ? "Movzu" : "Topic"}</h2> */}
        <div className="row" data-aos="zoom-in"
              data-aos-duration="700">
          <div
            class="elementor-element elementor-element-8d38da4 e-con-full e-flex e-con e-child container"
            data-id="8d38da4"
            data-element_type="container"
          >
            <div
              class="elementor-element elementor-element-641bd78 elementor-widget elementor-widget-heading"
              data-id="641bd78"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              {/* <div class="elementor-widget-container topics">
                <h2
                  class="elementor-heading-title elementor-size-default"
                >
                  Medianın gələcəyi: Ənənəvi media üçün növbəti addımlar;
                  Rəqəmsal transformasiya diqqət mərkəzində
                </h2>
                <h2
                  class="elementor-heading-title elementor-size-default"
                >
                  Müasir informasiya mühitində media menecmenti və dayanıqlı
                  media biznes modellərinin yaradılması
                </h2>
                <h2
                  class="elementor-heading-title elementor-size-default"
                >
                  Yeni mediada istehlak tendensiyaları və media savadlılığı
                  (dezinformasiya və feyk nyusla mübarizə üsulları)
                </h2>
                <h2
                  class="elementor-heading-title elementor-size-default"
                >
                  Jurnalistlərin təhlükəsizliyi
                </h2>
              </div> */}

              <div class="elementor-widget-container doc d-flex justify-content-between row">
                <div className="col-lg-6 col-sm-12 ">
                  <a href={!lang 
                    ? "https://admin.globalmediaforum.com/admin/assets/images/document/cbd4c9d9-5b46-4abb-94fa-247fcc87af70_Konsepsiya sənədi.pdf"
                    :"https://admin.globalmediaforum.com/admin/assets/images/document/f9d2f828-0d58-4a2f-9e50-9ca9c5313f0b_Concept%20note.pdf"} target="_blank">
                    <h2 class="elementor-heading-title elementor-size-default">
                      {!lang
                        ? "I Şuşa Qlobal Media Forumun konsepsiya sənədi"
                        : "Consept document of I Shusha Global Media Forum"}
                    </h2>
                  </a>
                </div>
                <div className="col-lg-5 col-sm-12  ">
                  <a href={!lang 
                    ? "https://admin.globalmediaforum.com/admin/assets/images/document/b6e02532-502c-40fe-8658-cc03a7b8b1b9_Agenda  AZE Forum.pdf" 
                    :"https://admin.globalmediaforum.com/admin/assets/images/document/3a9b16a3-543a-479d-b58a-dbbdbe847553_Agenda%20ENG%20Global%20Media%20Forum.pdf"} target="_blank">
                    <h2 class="elementor-heading-title elementor-size-default">
                      {!lang
                        ? "I Şuşa Qlobal Media Forumun proqramı"
                        : "Program of I Shusha Global Media Forum"}
                    </h2>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="topic">
        <h2>{!lang ? "I Şuşa Qlobal Media Forum mövzuları" : "The panel sessions of I Shusha Global Media Forum"}</h2>
        <div className="row mt-3" data-aos="zoom-in"
              data-aos-duration="700">
          <div
            class="elementor-element elementor-element-8d38da4 e-con-full e-flex e-con e-child container"
            data-id="8d38da4"
            data-element_type="container"
          >
            <div
              class="elementor-element elementor-element-641bd78 elementor-widget elementor-widget-heading"
              data-id="641bd78"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div class="elementor-widget-container topics">
                <h2 class="elementor-heading-title elementor-size-default">
                 
                  {!lang
                        ? "Medianın gələcəyi: Ənənəvi media üçün növbəti addımlar Rəqəmsal transformasiya diqqət mərkəzində"
                        : "The future of media: The next steps for traditional media, with a focus on digital transformation"}
                </h2>
                <h2 class="elementor-heading-title elementor-size-default">
                  {!lang
                        ? "Müasir informasiya mühitində media menecmenti və dayanıqlı media biznes modellərinin yaradılması"
                        : "Media management and the creation of sustainable business models in the modern information environment"}
                </h2>
                <h2 class="elementor-heading-title elementor-size-default">
                {!lang
                        ? "Yeni mediada istehlak tendensiyaları və media savadlılığı (dezinformasiya və feyk nyusla mübarizə üsulları)"
                        : "Consumption trends in new media and media literacy, including methods to counter disinformation and fake news"}
                  
                </h2>
                <h2 class="elementor-heading-title elementor-size-default">
                   {!lang
                        ? "Jurnalistlərin təhlükəsizliyi"
                        : "Safety of journalists"}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="news-detail-wrapper d-block mt-5">
      <h2>{!lang ? "Konfransdan fotolar" : "Photos from the conference"}</h2>
        <div className="arrow-wrap" style={{ flex: 1 }}></div>
        <div className="container news-detail-container">
          <div className="image-gallery-wrapper">
            <ImageGallery
              className="news-image-gallery"
              items={[...sliderImages]}
            />
          </div>
        </div>
        <div style={{ flex: 1 }}></div>
      </div> */}

      <div className="speakers-forum1 speaker-top">
        <h2>{!lang ? "Spikerlər" : "Speakers"}</h2>
        <div className="speakers-container">
          <Slider {...settingsSpeakers}>
            {speakers.map((speaker) => (
              <div key={speaker.Id} className="speaker-card"  data-aos="zoom-in"
              data-aos-duration="700">
                <img src={speaker.Image} alt={`${speaker.Fullname} photo`} />
                <div className="name">{speaker.Fullname}</div>
                <div
                  className="position"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeAndTruncateText(speaker.Information, 40),
                  }}
                ></div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="events-forum1 mb-4 news-top">
        <h2>
          {!lang
            ? "I Şuşa Qlobal Media Forumla bağlı xəbərlər"
            : "News about the I Shusha Global Media Forum"}
        </h2>
        <Slider {...settingsNews} >
          {news.map((item) => (
            <div key={item.Id} className="forum1-card" data-aos="zoom-in"
            data-aos-duration="700">
              <a href={`/news/${item.Id}`}>
                <div className="forum1-card-image">
                  <img src={item.Image} alt={`Event ${item.Id}`} />
                  <div className="forum1-card-date">{item.Time_converted}</div>
                </div>
                <div className="forum1-card-content">
                  <p
                    className="news_title"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeAndTruncateText(item.Title, 55),
                    }}
                  ></p>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Forum1;
